:root {
  --main-background-color: #fcfcfd;
  --main-text-color: #101828;
  --secondary-text-color: #344054;
  --navbar-background-color: #000000;
  --navbar-height: 51px;
}

body {
  background-color: var(--main-background-color);
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: var(--main-text-color);
  margin: 0;
  padding: 0;
}
